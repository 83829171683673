import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Layout, Seo } from "components"
import { Hero, About2, Features, Award, About, Signup } from "modules/home"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords={[
        "platforma zamówień publicznych",
        "zamówienia publiczne",
        "SIDAS PZP",
        "e-zamówienia",
        "IT w administracji",
        "Madkom SA",
      ]}
    />
    <Hero />
    <Award>
      <StaticImage
        src="../images/award.png"
        width={170}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Produkt roku IT w administracji"
        placeholder="blurred"
      />
    </Award>
    <About />
    <Signup />
    <Features />
    <About2 />
  </Layout>
)

export default IndexPage
